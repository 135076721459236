import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './OrderDetails.scss';
import { OrderDetailsT } from 'common/store/order-details/models';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import OrderRouteLocations from 'common/components/order-details/OrderRouteLocations/OrderRouteLocations';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import ExcludedCountries from 'common/components/ExcludedCountries/ExcludedCountries';
import CaseIcon from 'common/icons/CaseIcon';
import TrailerIcon from 'common/icons/TrailerIcon';
import EmissionIcon from 'common/icons/EmissionIcon';
import RouteIcon from 'common/icons/RouteIcon';
import { convertToKm } from 'common/utils/distance';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import SimpleTrailerTypeFormatter from 'design-system/components/InfoTable/formatters/SimpleTrailerTypeFormatter/SimpleTrailerTypeFormatter';
import InvoiceBinaryStatusPill from 'common/components/status-pill/InvoiceBinaryStatusPill/InvoiceBinaryStatusPill';
import ShipperPriceOfferInfoTable, {
    ShipperPriceOfferInfoTablePropsT,
} from 'common/components/info-tables/ShipperPriceOfferInfoTable/ShipperPriceOfferInfoTable';
import Invoice2Icon from 'common/icons/Invoice2Icon';
import PaperIcon from 'common/icons/PaperIcon';
import LinkFormatter from 'design-system/components/InfoTable/formatters/LinkFormatter/LinkFormatter';
import ShipperContractStatusPill from 'common/components/status-pill/ShipperContractStatusPill/ShipperContractStatusPill';
import ShipperContractLaneOrderCounterPill from 'common/components/pills/ShipperContractLaneOrderCounterPill/ShipperContractLaneOrderCounterPill';
import ShipperContractLaneStatusPill from 'common/components/status-pill/ShipperContractLaneStatusPill/ShipperContractLaneStatusPill';
import ShipmentDetailsCard from 'shipper/layouts/OrdersPage/OrderDetailsPage/OrderDetails/ShipmentDetailsCard/ShipmentDetailsCard';
import { urlFactory } from 'common/utils/urls';
import keyBy from 'lodash/keyBy';
import { useTrailersDict } from 'common/utils/hooks/useTrailersDict';
import isNil from 'lodash/isNil';
import EmissionClassFormatter from 'design-system/components/InfoTable/formatters/EmissionClassFormatter/EmissionClassFormatter';
import EntriesTabs, { EntryCountThemeEnum, EntryTabT } from 'common/components/tabs/EntriesTabs/EntriesTabs';
import { useEntriesTabs } from 'common/components/tabs/EntriesTabs/hooks';
import TourDetailsCard from 'shipper/layouts/OrdersPage/OrderDetailsPage/OrderDetails/TourDetailsCard/TourDetailsCard';
import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';
import { getDistanceByCountryRows } from 'design-system/components/InfoTable/hooks/distanceByCountry';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { formatISOTime, formatISOTimeWithoutTimezone } from 'common/utils/time';

type PropsT = {
    order: OrderDetailsT | null | undefined;
    goToUserDetails: (userId: UserIdT | null) => void;
};

const cx = classNames.bind(styles);

enum TabEnum {
    tours = 'tours',
    shipments = 'shipments',
}

const OrderDetails: React.FC<PropsT> = React.memo((props) => {
    const { order, goToUserDetails } = props;

    const { t } = useTranslation();

    const commonPriceDetails = useCommonPriceDetails();

    const { trailersDictById } = useTrailersDict();

    const waypointById = useMemo(() => {
        return keyBy(order?.waypoints, 'id');
    }, [order]);

    const priceOffer = order?.priceOffer || null;

    const { activeEntriesTabType, setActiveEntriesTabType } = useEntriesTabs<TabEnum>(TabEnum.shipments);

    const tabs = React.useMemo((): Array<EntryTabT<TabEnum> | null> => {
        const tourCount = order?.tours?.length || 0;
        return [
            {
                type: TabEnum.shipments,
                label: t('common:order-details.tab-titles.shipments'),
                counter: {
                    value: order?.shipments?.length,
                    theme: EntryCountThemeEnum.normal,
                },
            },
            tourCount
                ? {
                      type: TabEnum.tours,
                      label: t('common:order-details.tab-titles.tours'),
                      counter: {
                          value: tourCount,
                          theme: EntryCountThemeEnum.normal,
                      },
                  }
                : null,
        ];
    }, [t, order]);

    const details: Array<InfoTableRowT> = [
        {
            icon: (
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:order-details.columns.trailer-type'),
            value: (
                <SimpleTrailerTypeFormatter
                    trailerDictType={trailersDictById[String(order?.trailerInfo?.id)] || null}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'trailer',
        },
        {
            icon: <EmissionIcon strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('common:order-details.columns.emissions-class'),
            value: <EmissionClassFormatter emissionClass={order?.emissionClass} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'emission-class',
        },
    ];

    const creationDetails: Array<InfoTableRowT | null> = [
        {
            icon: (
                <CaseIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:order-details.columns.order'),
            value: order?.number,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'order-number',
            rows: [
                {
                    icon: null,
                    name: t('common:order-details.columns.created-on'),
                    value: <DateFormatter date={order?.createdOn} format="DD MMM YYYY, HH:mm" />,
                    emptyValue: t('common:info-table.placeholders.not-specified'),
                    isBoldValue: true,
                    testSelector: 'create-date',
                },
                {
                    icon: null,
                    name: t('common:order-details.columns.author'),
                    value: (
                        <UserLinkFormatter
                            fullName={order?.createdBy?.fullName}
                            userId={order?.createdBy?.id}
                            byBroker={order?.createdByBroker}
                            onOpenUserDetails={() => {
                                goToUserDetails(order?.createdBy?.id || null);
                            }}
                        />
                    ),
                    emptyValue: t('common:info-table.placeholders.not-specified'),
                    isBoldValue: true,
                    testSelector: 'author',
                },
                order?.shipperContract
                    ? {
                          icon: (
                              <PaperIcon
                                  size={DEFAULT_ICON_SIZE}
                                  strokeColor={StyleGuideColorsEnum.gray}
                                  fillColor={StyleGuideColorsEnum.light}
                              />
                          ),
                          name: t('common:order-details.columns.shipper-contract'),
                          value: (
                              <LinkFormatter
                                  to={urlFactory.shipperContractDetails({
                                      shipperContractId: order?.shipperContract?.id || '-',
                                  })}
                              >
                                  {order?.shipperContract?.name}
                              </LinkFormatter>
                          ),
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          rightNode: order?.shipperContract ? (
                              <ShipperContractStatusPill isSymmetrical status={order?.shipperContract?.status} />
                          ) : null,
                          isBoldValue: true,
                          testSelector: 'shipper-contract',
                      }
                    : null,
                order?.shipperContractLane
                    ? {
                          icon: (
                              <PaperIcon
                                  size={DEFAULT_ICON_SIZE}
                                  strokeColor={StyleGuideColorsEnum.gray}
                                  fillColor={StyleGuideColorsEnum.light}
                              />
                          ),
                          name: t('common:order-details.columns.shipper-contract-lane'),
                          value: (
                              <LinkFormatter
                                  to={urlFactory.shipperContractLaneDetails({
                                      shipperContractId: order?.shipperContract?.id || '-',
                                      shipperContractLaneId: order?.shipperContractLane?.id || '-',
                                  })}
                              >
                                  {order?.shipperContractLane?.tztLaneId}
                              </LinkFormatter>
                          ),
                          rightNode: (
                              <>
                                  <ShipperContractLaneOrderCounterPill
                                      ordersLeft={order?.shipperContractLane?.ordersLeft}
                                      maxNumberOfOrders={order?.shipperContractLane?.maxNumberOfOrders}
                                      status={order?.shipperContract?.status}
                                      isSymmetrical
                                  />
                                  <ShipperContractLaneStatusPill
                                      isSymmetrical
                                      status={order?.shipperContractLane?.status}
                                  />
                              </>
                          ),
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          isBoldValue: true,
                          testSelector: 'shipper-contract-lane',
                      }
                    : null,
            ],
        },
    ];

    const relativeEntities: Array<InfoTableRowT> = [
        {
            icon: (
                <Invoice2Icon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:order-details.columns.invoice'),
            value: order?.invoiceNumber,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            rightNode: <InvoiceBinaryStatusPill isPaid={!!order?.paid} isSymmetrical />,
            testSelector: 'invoice',
        },
    ];

    const distanceByCountryRows = getDistanceByCountryRows(priceOffer?.distanceByCountry);

    const mileageDetails: Array<InfoTableRowT | null> = [
        {
            icon: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
            name: t('common:order-details.columns.payload-mileage'),
            value: (
                <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(priceOffer?.distance)} />
            ),
            emptyValue: t('common:info-table.placeholders.empty'),
            isBoldValue: true,
            testSelector: 'total-mileage',
            rows: distanceByCountryRows,
        },
    ];

    const additionalServices = useMemo((): ShipperPriceOfferInfoTablePropsT['additionalServices'] => {
        if (!order?.additionalServiceCosts) {
            return [];
        }

        return order.additionalServiceCosts.reduce<NonNullable<ShipperPriceOfferInfoTablePropsT['additionalServices']>>(
            (acc, additionalServiceCost) => {
                if (additionalServiceCost.type && !isNil(additionalServiceCost.cost)) {
                    acc.push({
                        enum: additionalServiceCost.type,
                        cost: additionalServiceCost.cost,
                    });
                }

                return acc;
            },
            [],
        );
    }, [order?.additionalServiceCosts]);

    const firstWaypoint = order?.waypoints?.[0] || null;
    const lastWaypoint = order?.waypoints?.[(order?.waypoints?.length || 0) - 1] || null;

    return (
        <>
            <OrderRouteLocations
                className={cx('route')}
                origin={firstWaypoint?.address}
                pickupDockingHoursFrom={firstWaypoint?.correctedDateTimeFrom || firstWaypoint?.originalDateTimeFrom}
                pickupDockingHoursTo={firstWaypoint?.correctedDateTimeTo || firstWaypoint?.originalDateTimeTo}
                destination={lastWaypoint?.address}
                dropOffDockingHoursFrom={lastWaypoint?.correctedDateTimeFrom || lastWaypoint?.originalDateTimeFrom}
                dropOffDockingHoursTo={lastWaypoint?.correctedDateTimeTo || lastWaypoint?.originalDateTimeTo}
            />
            <ExcludedCountries
                className={cx('excluded-countries')}
                titleNode={t('common:order-details.excluded-countries.title')}
                countryCodes={order?.prohibitedCountries || []}
                tooltipNode={t('order-details.excluded-countries.tooltip')}
            />
            {order?.etaToNextWaypoint && (
                <Alert
                    icon={
                        <TimeWindowIcon
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    }
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.gray}
                    className={cx('alert')}
                    tooltipNode={
                        order?.truckCoordinatesUpdatedAt ? (
                            <TooltipContent width={150} isCenter theme={TooltipContentThemeEnum.black}>
                                {t('common:order-details.alerts.sync-time', {
                                    time: formatISOTimeWithoutTimezone(order?.truckCoordinatesUpdatedAt),
                                })}
                            </TooltipContent>
                        ) : null
                    }
                >
                    {t('common:order-details.alerts.eta', {
                        time: formatISOTime(order?.etaToNextWaypoint),
                    })}
                </Alert>
            )}
            <InfoTable
                shouldRenderIcons
                className={cx('table')}
                rows={creationDetails}
                testSelector="creation-details"
            />
            <InfoTable
                shouldRenderIcons
                className={cx('table')}
                rows={relativeEntities}
                testSelector="relative-entities"
            />
            <InfoTable shouldRenderIcons className={cx('table')} rows={mileageDetails} testSelector="mileage-details" />
            <InfoTable shouldRenderIcons className={cx('table')} rows={details} testSelector="details" />
            <ShipperPriceOfferInfoTable
                className={cx('table')}
                hasContract={!!order?.shipperContract}
                totalPriceLabel={t('common:order-details.columns.price')}
                totalPrice={order?.priceOffer?.totalPrice}
                lineHaulCost={order?.priceOffer?.lineHaul}
                teamDriveCost={order?.priceOffer?.teamDriveCost}
                co2={order?.priceOffer?.co2}
                tollCost={order?.priceOffer?.tollCost}
                tollByRoadType={order?.priceOffer?.tollByRoadType}
                tranziitServiceFee={order?.priceOffer?.serviceFee}
                urgentOverprice={order?.priceOffer?.urgentOverprice}
                layoverCost={order?.priceOffer?.layoverCost}
                layoverSeconds={order?.priceOffer?.layoverSeconds}
                fuelCost={order?.priceOffer?.fuelCost}
                fuelByCountry={order?.priceOffer?.fuelByCountry}
                greenOverprice={order?.priceOffer?.greenOverprice}
                additionalServicesCost={order?.priceOffer?.additionalServicesCost}
                additionalServices={additionalServices}
                shouldAlwaysRenderExpandTrigger
            />
            <EntriesTabs
                className={cx('tabs')}
                activeEntriesTabType={activeEntriesTabType}
                setActiveEntriesTabType={setActiveEntriesTabType}
                tabs={tabs}
            />
            {activeEntriesTabType === TabEnum.shipments && (
                <>
                    {order?.shipments?.map((shipment, shipmentIndex) => {
                        return (
                            <ShipmentDetailsCard
                                key={shipmentIndex}
                                className={cx('shipment')}
                                shipment={shipment}
                                isReeferTrailer={!!order?.trailerInfo?.reefer}
                                pickUpWaypoint={waypointById[shipment?.pickupPointId]}
                                dropOffWaypoint={waypointById[shipment?.dropOffPointId]}
                                shipmentNumber={shipmentIndex + 1}
                                keyboardShortcut={String(shipmentIndex + 1)}
                            />
                        );
                    })}
                </>
            )}
            {activeEntriesTabType === TabEnum.tours && (
                <>
                    {order?.tours?.map((tour, tourIndex) => {
                        return (
                            <TourDetailsCard
                                key={tourIndex}
                                tourNumber={tourIndex + 1}
                                tour={tour}
                                className={cx('tour')}
                                keyboardShortcut={String(tourIndex + 1)}
                            />
                        );
                    })}
                </>
            )}
        </>
    );
});

export default OrderDetails;
