import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './RFQDetails.scss';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import OrderRouteLocations from 'common/components/order-details/OrderRouteLocations/OrderRouteLocations';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import ExcludedCountries from 'common/components/ExcludedCountries/ExcludedCountries';
import RouteIcon from 'common/icons/RouteIcon';
import TrailerIcon from 'common/icons/TrailerIcon';
import { RFQDetailsT } from 'common/store/rfq-details/models';
import { useDispatch, useSelector } from 'react-redux';
import { selectTrailersDictById, selectTrailersDictRequest } from 'common/store/trailers-dict/selectors';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { convertToKm } from 'common/utils/distance';
import EuroSymbolIcon from 'common/icons/EuroSymbolIcon';
import { isNonNil } from 'common/utils';
import { formatTimeInterval } from 'common/utils/time';
import SimpleTrailerTypeFormatter from 'design-system/components/InfoTable/formatters/SimpleTrailerTypeFormatter/SimpleTrailerTypeFormatter';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import OrderStatusPill from 'common/components/status-pill/OrderStatusPill/OrderStatusPill';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import { urlFactory } from 'shipper/utils/urls';
import CaseIcon from 'common/icons/CaseIcon';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import { CountryCodeT } from 'common/store/countries-dict/models';
import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';
import FeeIcon from 'common/icons/FeeIcon';
import CalendarIcon from 'common/icons/CalendarIcon';
import AccountIcon from 'common/icons/AccountIcon';
import NumberIcon from 'common/icons/NumberIcon';
import StopWarningIcon from 'common/icons/StopWarningIcon';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import WarningTextFormatter from 'design-system/components/InfoTable/formatters/WarningTextFormatter/WarningTextFormatter';
import { checkShouldShowLayover } from 'common/components/PriceDetails/utils';
import { getDistanceByCountryRows } from 'design-system/components/InfoTable/hooks/distanceByCountry';

type PropsT = {
    rfqDetails: RFQDetailsT | null;
    goToUserDetails: (userId: UserIdT | null) => void;
};

const cx = classNames.bind(styles);

const RFQDetails: React.FC<PropsT> = React.memo((props) => {
    const { rfqDetails, goToUserDetails } = props;

    const trailersDictRequest = useSelector(selectTrailersDictRequest);
    const trailersDictById = useSelector(selectTrailersDictById);
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const trailerDict = trailersDictById[String(rfqDetails?.trailerTypeId)] || null;

    const { t } = useTranslation();

    const distanceByCountryRows = getDistanceByCountryRows(rfqDetails?.priceOffer?.distanceByCountry);

    const moveDetails: Array<InfoTableRowT> = [
        {
            icon: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
            name: t('common:rfq-details.columns.distance'),
            value: rfqDetails?.priceOffer?.distance ? (
                <UnitTypeCount
                    count={convertToKm(rfqDetails.priceOffer?.distance)}
                    type={UnitTypeEnum.kilometersAbbreviation}
                />
            ) : null,
            emptyValue: t('common:info-table.placeholders.empty'),
            isBoldValue: true,
            hasBottomBorder: false,
            testSelector: 'distance',
            rows: distanceByCountryRows,
        },
    ];

    const trailerTypeDetails: Array<InfoTableRowT> = [
        {
            icon: (
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:rfq-details.columns.trailer-type'),
            value: <SimpleTrailerTypeFormatter trailerDictType={trailerDict} />,
            isLoading: trailersDictRequest.loading,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'trailer-type',
        },
    ];

    const commonPriceDetails = useCommonPriceDetails();

    const getPriceDetails = (priceOffer: RFQDetailsT['priceOffer'] | RFQDetailsT['completedPrice'], label: string) => {
        const priceDetails: Array<PriceDetailT | null> = [
            {
                iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
                title: label,
                price: priceOffer?.totalPrice,
                list: [
                    {
                        ...commonPriceDetails.lineHaul,
                        price: priceOffer?.lineHaul,
                    },
                    {
                        ...commonPriceDetails.getRoadTaxes(priceOffer?.co2),
                        price: priceOffer?.tollCost,
                        list: commonPriceDetails.getRoadTaxesList(priceOffer?.tollByRoadType),
                    },
                    {
                        ...commonPriceDetails.fuelCost,
                        price: priceOffer?.fuelCost,
                        list: commonPriceDetails.getFuelCostsByCountryList(priceOffer?.fuelByCountry),
                    },
                    {
                        iconNode: <FeeIcon strokeColor={StyleGuideColorsEnum.slate} />,
                        title: t('common:order-details.columns.tranziit-service-fee-cost'),
                        price: priceOffer?.serviceFee,
                    },
                    priceOffer?.urgentOverprice
                        ? {
                              ...commonPriceDetails.urgentOverprice,
                              price: priceOffer?.urgentOverprice,
                          }
                        : null,
                    priceOffer?.greenOverprice
                        ? {
                              ...commonPriceDetails.greenSurcharge,
                              price: priceOffer?.greenOverprice,
                          }
                        : null,
                    checkShouldShowLayover(priceOffer?.layoverSeconds, priceOffer?.layoverCost)
                        ? {
                              ...commonPriceDetails.getLayoverCost(priceOffer?.layoverSeconds),
                              price: priceOffer?.layoverCost || 0,
                          }
                        : null,
                ],
            },
        ].filter(isNonNil);

        return priceDetails;
    };

    const defaultPriceDetailsList = rfqDetails?.priceOffer
        ? getPriceDetails(rfqDetails.priceOffer, t('common:rfq-details.columns.default-price'))
        : null;

    const targetPriceDetailsList = rfqDetails?.targetPrice
        ? [
              {
                  iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
                  title: t('common:rfq-details.columns.target-price'),
                  price: rfqDetails?.targetPrice,
              },
          ]
        : [];

    const completedPriceDetailsList = rfqDetails?.completedPrice
        ? getPriceDetails(rfqDetails.completedPrice, t('common:rfq-details.columns.selected-price'))
        : null;

    const orderDetails: Array<InfoTableRowT | null> = [
        {
            icon: (
                <CaseIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:rfq-details.columns.order'),
            value: rfqDetails?.orderId ? (
                <Link to={urlFactory.orderDetails(rfqDetails.orderId)} theme={LinkThemeEnum.boldBrandDark}>
                    {rfqDetails.orderNumber}
                </Link>
            ) : null,
            emptyValue: t('common:rfq-details.not-placed-order'),
            isBoldValue: true,
            testSelector: 'order',
            rightNode: rfqDetails?.orderId ? <OrderStatusPill status={rfqDetails.orderStatus} /> : null,
        },
        rfqDetails?.externalNumber
            ? {
                  icon: null,
                  name: t('common:rfq-details.columns.external-number'),
                  value: rfqDetails?.externalNumber,
                  emptyValue: null,
                  isBoldValue: true,
                  testSelector: 'external-number',
              }
            : null,
    ].filter(isNonNil);

    const creationDetails: Array<InfoTableRowT> = [
        {
            icon: (
                <CalendarIcon
                    size={DEFAULT_ICON_SIZE}
                    fillColor={StyleGuideColorsEnum.light}
                    strokeColor={StyleGuideColorsEnum.gray}
                />
            ),
            name: t('common:rfq-details.columns.create-date'),
            value: rfqDetails?.createdDate ? (
                <DateFormatter date={rfqDetails.createdDate} format="DD MMM YYYY, HH:mm" />
            ) : null,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'create-date',
            hasBottomBorder: true,
        },
        {
            icon: (
                <AccountIcon
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                    size={DEFAULT_ICON_SIZE}
                />
            ),
            name: t('common:rfq-details.columns.created-by'),
            value: (
                <UserLinkFormatter
                    fullName={rfqDetails?.createdByName}
                    userId={rfqDetails?.createdById}
                    byBroker={rfqDetails?.createdByBroker}
                    onOpenUserDetails={goToUserDetails}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'created-by',
        },
    ].filter(isNonNil);

    const expirationDetails: Array<InfoTableRowT> | null = rfqDetails?.expiration
        ? [
              {
                  icon: (
                      <CalendarIcon
                          size={DEFAULT_ICON_SIZE}
                          fillColor={StyleGuideColorsEnum.light}
                          strokeColor={StyleGuideColorsEnum.gray}
                      />
                  ),
                  name: t('common:rfq-details.columns.expire-on'),
                  value: <DateFormatter date={rfqDetails.expiration} format="DD MMM YYYY, HH:mm" />,
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'expiration-date',
                  hasBottomBorder: false,
              },
          ]
        : null;

    const completedDetails: Array<InfoTableRowT> | null = rfqDetails?.completedDate
        ? [
              {
                  icon: (
                      <CalendarIcon
                          size={DEFAULT_ICON_SIZE}
                          fillColor={StyleGuideColorsEnum.light}
                          strokeColor={StyleGuideColorsEnum.gray}
                      />
                  ),
                  name: t('common:rfq-details.columns.completed-by'),
                  value: <DateFormatter date={rfqDetails?.completedDate} format="DD MMM YYYY, HH:mm" />,
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'completed-date',
                  hasBottomBorder: true,
              },
              {
                  icon: (
                      <AccountIcon
                          strokeColor={StyleGuideColorsEnum.gray}
                          fillColor={StyleGuideColorsEnum.light}
                          size={DEFAULT_ICON_SIZE}
                      />
                  ),
                  name: t('common:rfq-details.columns.completed-by'),
                  value: (
                      <UserLinkFormatter
                          fullName={rfqDetails?.completedByName}
                          userId={rfqDetails?.completedById}
                          onOpenUserDetails={goToUserDetails}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'completed-by',
              },
          ]
        : null;

    const excludedCountries = (rfqDetails?.prohibitedCountries as Array<CountryCodeT>) || [];

    const points = rfqDetails?.points || [];
    const firstPoint = points[0] || null;
    const lastPoint = points[points.length - 1] || null;

    if (!rfqDetails) {
        return null;
    }

    return (
        <div className={cx('container')}>
            <OrderRouteLocations
                className={cx('route')}
                origin={firstPoint?.address}
                pickupDockingHoursFrom={firstPoint?.from}
                pickupDockingHoursTo={firstPoint?.to}
                destination={lastPoint?.address}
                dropOffDockingHoursFrom={lastPoint?.from}
                dropOffDockingHoursTo={lastPoint?.to}
            />
            <ExcludedCountries
                className={cx('excluded-countries')}
                titleNode={t('common:rfq-details.excluded-countries.title')}
                countryCodes={excludedCountries}
                tooltipNode={t('common:rfq-details.excluded-countries.tooltip')}
            />
            <InfoTable shouldRenderIcons className={cx('table')} rows={orderDetails} testSelector="order-details" />
            <InfoTable shouldRenderIcons className={cx('table')} rows={moveDetails} testSelector="move-details" />
            <InfoTable
                shouldRenderIcons
                className={cx('table')}
                rows={trailerTypeDetails}
                testSelector="trailer-details"
            />
            {points.map((point, index) => {
                const pointDetails: Array<InfoTableRowT | null> = [
                    {
                        icon: (
                            <NumberIcon
                                number={index + 1}
                                fillColor={point?.driveThru ? StyleGuideColorsEnum.gray : StyleGuideColorsEnum.charcoal}
                            />
                        ),
                        name: t('common:rfq-details.columns.waypoint-number', {
                            number: index + 1,
                        }),
                        value: <LocationLabel format="s1_s2_zip_city_country" location={point?.address} />,
                        emptyValue: t('common:info-table.placeholders.not-specified'),
                        isBoldValue: true,
                        rows: [
                            point?.from || point?.to
                                ? {
                                      icon: point?.brokerWindow ? (
                                          <Tooltip
                                              position={TooltipPositionEnum.centerRight}
                                              theme={TooltipThemeEnum.black}
                                              tooltipNode={
                                                  <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                                                      {t('common:rfq-details.tooltips.broker-appointment')}
                                                  </TooltipContent>
                                              }
                                          >
                                              {(isShow, childrenClassName) => (
                                                  <StopWarningIcon
                                                      className={childrenClassName}
                                                      fillColor={StyleGuideColorsEnum.tomatoRed}
                                                  />
                                              )}
                                          </Tooltip>
                                      ) : null,
                                      name: t('common:rfq-details.columns.time-slot'),
                                      value: (
                                          <WarningTextFormatter isActive={!!point?.brokerWindow}>
                                              {formatTimeInterval(point?.from, point?.to)}
                                          </WarningTextFormatter>
                                      ),
                                      emptyValue: t('common:info-table.placeholders.not-specified'),
                                      isBoldValue: true,
                                  }
                                : null,
                        ],
                    },
                ];

                return (
                    <InfoTable
                        key={`route-point-details-${index}`}
                        shouldRenderIcons
                        className={cx('table')}
                        rows={pointDetails}
                        testSelector={`route-point-details-${index}`}
                    />
                );
            })}
            {Boolean(targetPriceDetailsList?.length) && (
                <>
                    <PriceDetails
                        className={cx('price-details', {
                            'price-details--target': true,
                        })}
                        list={targetPriceDetailsList}
                        shouldAlwaysRenderExpandTrigger
                    />
                    <div className={cx('separator')} />
                </>
            )}
            {defaultPriceDetailsList && (
                <PriceDetails
                    className={cx('price-details', {
                        'price-details--default': true,
                    })}
                    list={defaultPriceDetailsList}
                />
            )}
            {completedPriceDetailsList && (
                <>
                    <div className={cx('separator')} />
                    <PriceDetails
                        className={cx('price-details', {
                            'price-details--selected': true,
                        })}
                        list={completedPriceDetailsList}
                    />
                </>
            )}
            <InfoTable
                shouldRenderIcons
                className={cx('table')}
                rows={creationDetails}
                testSelector="creation-details"
            />
            {completedDetails && (
                <InfoTable
                    shouldRenderIcons
                    className={cx('table')}
                    rows={completedDetails}
                    testSelector="completed-details"
                />
            )}
            {expirationDetails && (
                <InfoTable
                    shouldRenderIcons
                    className={cx('table')}
                    rows={expirationDetails}
                    testSelector="expiration-details"
                />
            )}
        </div>
    );
});

export default RFQDetails;
